.detectListContainer {
  height: 100%;

  .detectListContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    margin-left: 12px;
  }
}