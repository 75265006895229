.header {
  width: 100%;
  padding: 5px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icons li {
    background: none repeat scroll 0 0 white;
    height: 7px;
    width: 7px;
    line-height: 0;
    list-style: none outside none;
    margin-right: 15px;
    margin-top: 3px;
    vertical-align: top;
    border-radius: 50%;
    pointer-events: none;
  }
}

.container {
  width: 100%;
  padding: 5px;
}

.mrgnbtm {
  margin-top: 20px;
}

.btncenter {
  text-align: center;
}

.mrgnttm10 {
  margin-bottom: 10px;
}