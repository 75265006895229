.detectBlockContainer {
  display: flex;
  flex-direction: row;
  max-width: 250px;
  width: 100%;
  height: 100px;
}

.left {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  text-align: right;

  .text {
    font-size: 24px;
  }
}

.green {
  color: rgba(39, 249, 143);
}

.red {
  color: rgb(233, 8, 73);
}