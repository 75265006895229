.layoutContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  gap: 32px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    gap: 32px;
    padding: 0 12px;
    width: 100%;
  }
}