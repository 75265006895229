.modalContent {
  background-image: linear-gradient(to right bottom, #334c7c, #2e4472, #283d68, #23355e, #1d2e54);

  .modal-content {
    border: none;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .modal-body {

    form {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}