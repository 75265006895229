code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

body {
    color: white !important;
    margin: 0;
    height: 100%;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background-image: linear-gradient(to right bottom, #334c7c, #2e4472, #283d68, #23355e, #1d2e54);
    box-sizing: border-box;
}

img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}

input, button, textarea, select {
    font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

#root {
    isolation: isolate;
}

a {
    color: #999999;
    text-decoration: none;
    width: fit-content;
    height: fit-content;
}

a:hover {
    color: #FFFAFA;
}

u {
    text-decoration-line: underline;
}

b {
    font-weight: 600;
}